// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-en-about-jsx": () => import("./../../../src/pages/en/about.jsx" /* webpackChunkName: "component---src-pages-en-about-jsx" */),
  "component---src-pages-en-page-jsx": () => import("./../../../src/pages/en/page.jsx" /* webpackChunkName: "component---src-pages-en-page-jsx" */),
  "component---src-pages-page-jsx": () => import("./../../../src/pages/page.jsx" /* webpackChunkName: "component---src-pages-page-jsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-en-index-jsx": () => import("./../../../src/templates/en/index.jsx" /* webpackChunkName: "component---src-templates-en-index-jsx" */),
  "component---src-templates-en-post-jsx": () => import("./../../../src/templates/en/post.jsx" /* webpackChunkName: "component---src-templates-en-post-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */)
}

